import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Locator } from "@livingmap/frontend-locator";
import { t } from "i18next";

import { useGetMapsQuery } from "@redux/services/mms";
import { Project } from "@redux/services/types";
import { setDefaultLanguage, setMapID } from "@redux/slices/applicationSlice";

import Header from "@components/Header/Header";
import PageTitle from "@components/PageTitle/PageTitle";

import { getProjectNames, isLivingMapUser } from "@utils";
import { useSetInitialProjectData } from "@hooks";

import keycloak from "../../keycloak";
import styles from "./LocatorView.module.scss";
import { Navigate } from "react-router-dom";

const LocatorView = () => {
  const dispatch = useAppDispatch();
  const mapID = useAppSelector((state) => state.application.mapID);
  const { data } = useGetMapsQuery();
  const projectNames = getProjectNames(data);
  const activeProject = projectNames?.find((project) => project.id === mapID);
  useSetInitialProjectData(projectNames);

  // TODO: remove feature flag
  if (
    !isLivingMapUser() ||
    !projectNames?.find((project) => project.id === "synbiotix_yeovil_hospital")
  )
    return <Navigate to="/features" />;

  const handleProjectSelect = (project: Project) => {
    dispatch(setMapID(project.id));
    dispatch(setDefaultLanguage(project.defaultLanguage));
  };

  return (
    <div data-qa="locator-view" className={styles["page-container"]}>
      <Header
        dataQA="features-view-header"
        projects={projectNames}
        activeProject={activeProject}
        onProjectSelect={handleProjectSelect}
      />
      <PageTitle
        dataQA="locator-view-page-title"
        heading={t("locator.links.title")}
        subheading={t("locator.links.description")}
      />
      <div data-qa="locator-container" className={styles["locator-container"]}>
        <Locator project={mapID ?? undefined} token={keycloak.token} />
      </div>
    </div>
  );
};

export default LocatorView;
